<template>
  <base-material-card
    class="file-documents"
    icon="mdi-file"
    title="Files"
  >
    <v-progress-linear
      v-if="initialLoading"
      indeterminate
    />
    <v-row v-else>
      <v-col
        cols="12"
        sm="5"
        class="overflow-80vh"
      >
        <v-treeview
          v-if="files.length"
          :items="files"
          :load-children="getFiles"
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon
              v-if="item.tag === 'folder'"
              @click="assignRightView(item)"
            >
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
            <v-icon v-else>
              mdi-file
            </v-icon>
          </template>

          <template v-slot:label="{ item }">
            <div @click="assignRightView(item)">
              {{ item.name }}
            </div>
          </template>
        </v-treeview>

        <h4
          v-else
          class="text-center"
        >
          No files
        </h4>
      </v-col>

      <v-divider
        vertical
        class="my-5"
      />

      <v-col class="overflow-70vh">
        <vue-dropzone
          id="dropzone"
          ref="dropzone"
          :options="dropOptions"
          :destroy-dropzone="true"
          @vdropzone-success="fileUploaded"
        />

        <v-banner single-line>
          <v-progress-circular
            v-if="loadingFiles"
            :size="20"
            :width="2"
            indeterminate
          />
          <template v-slot:actions>
            <v-btn
              :loading="creating"
              small
              color="primary"
              @click="createNewFolder"
            >
              <v-icon left>
                mdi-folder-plus-outline
              </v-icon>
              New Folder
            </v-btn>

            <v-btn
              :disabled="!Object.keys(paste).length"
              :loading="pasting"
              small
              color="secondary"
              @click="pasteHere"
            >
              <v-icon left>
                mdi-content-paste
              </v-icon>
              Paste Here
            </v-btn>
          </template>
        </v-banner>

        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              v-if="!initial"
              @click="getFilesUpwards"
            >
              <v-list-item-avatar>
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                ...
              </v-list-item-content>
            </v-list-item>
            <template v-if="selected.children && selected.children.length">
              <v-list-item
                v-for="(file, i) in selected.children"
                :key="i"
                @click="!editable[i] && checkFiles(file)"
              >
                <v-list-item-avatar>
                  <v-icon>
                    {{ file.tag === 'folder' ? 'mdi-folder' : 'mdi-file' }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-text-field
                    v-if="editable[i]"
                    v-model="file.name"
                    hide-details
                    dense
                    append-outer-icon="mdi-content-save"
                    @click:append-outer="rename(file, i)"
                  />
                  <v-list-item-title v-else>
                    {{ file.name | truncate(42) }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action @click="stopActions">
                  <v-speed-dial
                    v-model="activeFab[i]"
                    direction="left"
                  >
                    <template v-slot:activator>
                      <v-progress-circular
                        v-if="loadingAction[i]"
                        :size="20"
                        :width="2"
                        indeterminate
                      />
                      <v-btn
                        v-else
                        v-model="activeFab[i]"
                        icon
                        text
                      >
                        <v-icon>
                          mdi-dots-horizontal
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-tooltip
                      v-for="(action, j) in getActions(file)"
                      :key="j"
                      top
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          v-bind="attrs"
                          :color="action.color"
                          fab
                          x-small
                          class="mx-1"
                          @click="trigger(action.action, file, i)"
                          v-on="on"
                        >
                          <v-icon v-text="action.icon" />
                        </v-btn>
                      </template>

                      <span>{{ action.what }}</span>
                    </v-tooltip>
                  </v-speed-dial>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import download from 'downloadjs'
  import { mapActions } from 'vuex'
  import { Api } from '@/apis'
  import { flatFilterFiles, flatFindFile } from '@/constants'
  import vueDropzone from 'vue2-dropzone'

  export default {
    components: { vueDropzone },

    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
      policy: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      dropOptions: {
        url: '/crmdms/upload',
        dictDefaultMessage: '<b>DRAG, DROP or CLICK here to upload content.</b><br/><br/>Maximum 5 files upload at a time. Maximum per file upload size 1024mb.',
        timeout: 18000000,
        maxFilesize: 1024,
        parallelUploads: 5,
      },
      files: [],
      initialLoading: true,
      selected: {},
      loadingFiles: false,
      activeFab: {},
      actions: [
        { color: 'success', icon: 'mdi-cloud-download-outline', what: 'Download', action: 'download' },
        { color: 'warning', icon: 'mdi-folder-move-outline', what: 'Move', action: 'move' },
        { color: 'info', icon: 'mdi-rename-box', what: 'Rename', action: 'rename' },
        { color: 'secondary', icon: 'mdi-content-copy', what: 'Copy', action: 'copy' },
        { color: 'error', icon: 'mdi-delete-outline', what: 'Delete', action: 'destroy' },
      ],
      loadingAction: {},
      paste: {},
      pasting: false,
      editable: {},
      creating: false,
      initial: true,
      incrementId: 0,
    }),

    computed: {
      request () {
        if (Object.keys(this.policy).length) {
          const companyId = this.policy.insured_company_id
          const userId = this.policy.insured_user_id

          return {
            location: `${companyId ? 'company' : 'user'}/${companyId || userId}`,
            storageType: 'policy',
          }
        } else {
          const { id, user } = this.customer
          if (id && user >= 0) {
            const storageType = user ? 'user' : 'company'
            return {
              location: id,
              storageType,
            }
          }
        }

        return { location: '', storageType: '' }
      },
    },

    mounted () {
      this.getFiles()
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getFiles (file) {
        try {
          if (!file) {
            this.initial = true
            this.initialLoading = true
          } else {
            this.initial = false
          }

          const { location, storageType } = this.request
          this.loadingFiles = true
          const request = {
            storage_type: storageType || (file ? file.storage_type : ''),
            location: file ? file.location : location,
          }
          if (this.$refs.dropzone) {
            this.$refs.dropzone.setOption('params', request)
          } else {
            this.dropOptions.params = request
          }

          if (file && file.children && file.children.length) {
            this.selected = file
          } else {
            const files = await axios.post('/crmdms/list', request)
            const filesData = files.data.map(mappedFile => ({
              ...mappedFile,
              children: mappedFile.tag === 'folder' ? [] : null,
              id: this.incrementId++,
            }))

            if (file) {
              file.children = filesData
              this.selected = file
            } else {
              this.files = filesData
              this.selected = {
                ...this.selected,
                location,
                children: filesData,
              }
            }
          }
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }

        this.initialLoading = false
        this.loadingFiles = false
      },

      checkFiles (file) {
        if (file.tag === 'folder') {
          this.getFiles(file)
        } else {
          window.open(file.storage_link, '_blank')
        }
      },

      assignRightView (file) {
        if (file.tag === 'folder') {
          this.selected = file
          const { clientType, clientId, storageType } = this.request
          this.$refs.dropzone.setOption('params', {
            client_type: clientType,
            client_id: clientId,
            storage_type: storageType,
            location: file ? file.location : '',
          })
        }
      },

      fileUploaded (file, res) {
        this.$refs.dropzone.removeAllFiles()
        this.showSnackBar({ text: res.message, color: 'success' })
        this.selected.children.push({ name: res.name, tag: 'file', location: res.location, id: this.incrementId++ })
      },

      stopActions (e) {
        e.preventDefault()
        e.stopPropagation()
      },

      trigger (action, file, index) {
        switch (action) {
          case 'move': {
            this.paste = {
              action: 'move',
              name: file.name,
              location: file.location,
              tag: file.tag,
            }
            return
          }
          case 'copy': {
            this.paste = {
              action: 'copy',
              name: file.name,
              location: file.location,
              tag: file.tag,
            }
            return
          }
          case 'rename': {
            this.editable[index] = true
            return
          }
          case 'destroy':
            return this.destroy(file, index)
          case 'download':
            return this.download(file, index)
        }
      },

      async destroy (file, index) {
        const confirm = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
        if (confirm) {
          this.loadingAction = {
            ...this.loadingAction,
            [index]: true,
          }
          const response = await new Api().post({
            url: `/crmdms/${file.tag === 'folder' ? 'destroy-folder' : 'destroy'}`,
            request: { storage_type: this.request.storageType, location: file.location },
          })

          if (response) {
            this.selected = {
              ...this.selected,
              children: this.selected.children.filter(f => f.location !== file.location),
            }
            this.files = flatFilterFiles(file.location, this.files)
          }

          this.loadingAction = {
            ...this.loadingAction,
            [index]: false,
          }
        }
      },

      async download (file, index) {
        this.loadingAction = {
          ...this.loadingAction,
          [index]: true,
        }

        try {
          const response = await axios({
            url: '/crmdms/download',
            method: 'POST',
            responseType: 'blob',
            timeout: 18000000,
            data: {
              storage_type: this.request.storageType,
              location: file.location,
            },
          })
          download(response.data, file.name)
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }

        this.loadingAction = {
          ...this.loadingAction,
          [index]: false,
        }
      },

      async pasteHere () {
        this.pasting = true
        const url = `/crmdms/${this.paste.action}${this.paste.tag === 'folder' ? '-folder' : ''}`
        const newLocation = `${this.selected.location}/${this.paste.name}`
        const response = await new Api().post({
          url,
          request: {
            storage_type: this.request.storageType,
            location_old: this.paste.location,
            location_new: newLocation,
          },
        })

        if (response) {
          if (this.paste.action === 'move') {
            this.files = flatFilterFiles(this.paste.location, this.files)
          }
          this.selected.children.push({
            id: this.incrementId++,
            location: newLocation,
            name: this.paste.name,
            tag: this.paste.tag,
            children: this.paste.tag === 'folder' ? [] : null,
          })
          this.paste = {}
        }
        this.pasting = false
      },

      async rename (file, index) {
        this.loadingAction = {
          ...this.loadingAction,
          [index]: true,
        }

        if (!file.name || file.name.includes('/')) {
          this.showSnackBar({ text: 'File name should not be empty or contain / in it.', color: 'error' })
          this.loadingAction = {
            ...this.loadingAction,
            [index]: false,
          }
          return
        }

        await new Api().post({
          url: `/crmdms/rename${file.tag === 'folder' ? '-folder' : ''}`,
          request: {
            storage_type: this.request.storageType,
            location: file.location,
            new_name: file.name,
          },
        })

        this.loadingAction = {
          ...this.loadingAction,
          [index]: false,
        }
        this.editable = { [index]: false }
      },

      async createNewFolder () {
        this.creating = true
        const { storageType } = this.request
        const folderName = 'New Folder'
        const response = await new Api().post({
          url: '/crmdms/create',
          request: {
            storage_type: storageType,
            location: `${this.selected.location}/${folderName}`,
          },
        })
        if (response) {
          this.selected.children.push({
            id: this.incrementId++,
            location: this.selected.location + '/' + folderName,
            name: folderName,
            tag: 'folder',
            children: [],
          })
        }
        this.creating = false
      },

      getFilesUpwards () {
        const upwardLocation = this.selected.location.split('/').slice(0, -1).join('/')
        if (upwardLocation === this.request.location) {
          this.selected = {
            ...this.selected,
            location: upwardLocation,
            name: upwardLocation,
            children: this.files,
          }
        } else if (upwardLocation) {
          this.selected = flatFindFile(upwardLocation, this.files)
        }
      },

      getActions (file) {
        return file.tag === 'folder' ? this.actions.filter((action) => action.action !== 'download') : this.actions
      },
    },
  }
</script>

<style lang="sass" scoped>
.overflow-80vh
  height: 80vh
  overflow-y: auto

.overflow-70vh
  height: 70vh
  overflow-y: auto
</style>
